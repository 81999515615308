import { Link } from "gatsby"
import React from "react"
// import ScrollAnimation from 'react-animate-on-scroll';
import PropTypes from "prop-types"

const CenteredSlide = ({ image, title, link, delay }) => (
    <div className="column carousel-item">
        <img src={ image } alt={ title } className="width-100 gm-bottom--1" />
        <Link to={ link }><p className="p--large no-margin-bottom">{ title }</p></Link>
  	    <Link to={ link } className="underline underline--white gm-bottom--2">Read more</Link>
    </div>
)

CenteredSlide.propTypes = {
  image: PropTypes.string,
  title: PropTypes.string,
  link: PropTypes.string,
}

CenteredSlide.defaultProps = {
  image: ``,
  title: ``,
  link: ``,
  delay:0,
}

export default CenteredSlide
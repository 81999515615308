import { Link } from "gatsby"
import React from "react"
import Slider from "react-slick";

const settings = {
  centerMode: true,
  centerPadding: "25%",
  arrows: false,
  dots: false, 
  swipeToSlide: true,
  responsive: [
    { breakpoint: 768,
      settings: {
        centerPadding: "10%" 
      }
    }
  ]
};

const CenteredSlider = ({ children }) => (
  <>
    <div className="row shrink-width gm-top--1 gm-bottom--1">
      <div className="column small-12 medium-6 medium-text-left text-center">
        <h3 className="no-margin-bottom button-title">Insights</h3>
        <p className="hide-for-medium gm-bottom--1"></p>
      </div>
      <div className="column small-12 medium-6 text-center medium-text-right">
        <Link to="/blog" className="button no-margin-bottom">Read more</Link>
      </div>
    </div>
    <div className="row">
      <div className="column gm-bottom--2"></div>
    </div>
    <Slider {...settings}>
      { children }
    </Slider>
  </>
)

export default CenteredSlider